import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Retoure from '../../../components/ProductDetails/netstorsys/retoure'
import Seo from "../../../components/App/Seo"

const RetouremanagementPage = () => {
  return (
    <Layout>
      <Seo title={"Software für Retouren mit schneller Wiedereinlagerung"}
           description={"Das Management von Kundenrücksendungen / Retouren ist integraler Bestandteil im B2C " +
           "Geschäft und kann auf bis zu 70% des Absatzes anwachsen."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Retoure />
      <Footer />
    </Layout>
  );
}

export default RetouremanagementPage
