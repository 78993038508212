import React from "react"
import retoureHeader from "../../../assets/images/products/netstorsys/retoure_header.svg"
import wmRetoure from "../../../assets/images/products/netstorsys/wm-retoure.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"
import FeatureSection from "../../App/FeatureSection"

const Retoure = () => {
  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={retoureHeader} alt="Retouremanagement" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETSTORSYS</span>
                <h1 className={"pt-3"}>Retouremanagement / Retouren Software </h1>
                <p>
                  Das Management von Kundenrücksendungen, auch Retouren genannt, ist integraler Bestandteil aller
                  Business-to-Customer-Geschäfte
                  und kann auf bis zu 70% des Absatzes anwachsen. Somit ist der Retoureprozess von großer Bedeutung in
                  der Intralogistik und ist in der Regel ein Teilprozess eines Warehouse Management Systems.
                </p>
                <p>
                  NETSTORSYS unterstützt diesen Prozess durch auf Geschwindigkeit ausgelegte
                  Benutzerinteraktionen, die sowohl eine schneller Wiedereinlagerung ermöglich, als auch
                  Hilfestellung bei der Prüfung von Artikeln geben.
                </p>

                <WichtigeMerkmale items={[
                  "Wiedereinlagerung",
                  "Warenprüfung",
                  "Nicherstattungsprozess",
                  "schneller Prozess"
                ]} image={<img src={wmRetoure}
                               alt="Retoure Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <p>
                  Neben der Erfassung von Retouregründen und der Möglichkeit, Retouren abzulehnen, stehen auch
                  unterschiedliche Strategien zur Wiedereinlagerung bereit. Die Wahl der Hardware am Retourearbeitsplatz
                  wird dem Kunden aufgrund seiner individuellen Anforderungen selbst überlassen. Das Drucken von
                  auftragsorientierten Retoureformularen für den Versand oder den Internetstore ist selbstverständlich
                  ebenfalls Bestandteil des Produkts.
                </p>

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection product={"Netstorsys"} />


              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>

      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_retoure_framed.png"
                        alt="Retouremanagement in NETSTORSYS"
                        placeholder="blurred"
                        width={800}
                        className={"pb-20"}
                      />}
                      header={"Effizientes Retouremanagement"}
                      textBlock1={<span>Über die integrierte Auftragserfassung, können Retouren schnell und einfach direkt im System gebucht
                werden. Dabei wird sichergestellt, dass nur Artikel und Aufträge retourniert werden können die auch
                sicher bestellt und versendet wurden. Mittels anpassbarer Prozesse kann für jeden einzelnen Artikel
                entschieden werden, wie nach einer Retoure weiter damit verfahren werden soll.</span>}
      />
    </>
  )
}

export default Retoure
